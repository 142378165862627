import { type RegionsType } from 'types/regions'

export const CASANUESTRA = 'casanuestra' as const
export const CASANUESTRA_DEV = 'casanuestra_dev' as const
export const CASANUESTRA_STG = 'casanuestra_stg' as const
export const CBANK = 'cbank' as const
export const DEMO_AR = 'demo_ar' as const
export const DEMO_CL = 'demo_cl' as const
export const GMF_CL = 'gmf_cl' as const
export const HSP = 'hsp' as const
export const HSP_DEV = 'hsp_dev' as const
export const HSP_STG = 'hsp_stg' as const
export const LOCAL_CBANK = 'local_cbank' as const
export const MAF = 'maf' as const
export const MAF_DEV = 'maf_dev' as const
export const MAF_STG = 'maf_stg' as const
export const MAF_UAT = 'maf_uat' as const
export const MYV = 'myv' as const
export const PRESCIENTSECURITY1 = 'prescientsecurity1' as const
export const SUPV = 'supv' as const
export const SUPV_DEV = 'supv_dev' as const
export const SUPV_STG = 'supv_stg' as const

export const TENANTS = {
  [CASANUESTRA]: CASANUESTRA,
  [CASANUESTRA_DEV]: CASANUESTRA_DEV,
  [CASANUESTRA_STG]: CASANUESTRA_STG,
  [CBANK]: CBANK,
  [DEMO_AR]: DEMO_AR,
  [DEMO_CL]: DEMO_CL,
  [GMF_CL]: GMF_CL,
  [HSP]: HSP,
  [HSP_DEV]: HSP_DEV,
  [HSP_STG]: HSP_STG,
  [LOCAL_CBANK]: LOCAL_CBANK,
  [MAF_DEV]: MAF_DEV,
  [MAF_STG]: MAF_STG,
  [MAF_UAT]: MAF_UAT,
  [MAF]: MAF,
  [MYV]: MYV,
  [PRESCIENTSECURITY1]: PRESCIENTSECURITY1,
  [SUPV_DEV]: SUPV_DEV,
  [SUPV]: SUPV
} as const
export type TenantsType = (typeof TENANTS)[keyof typeof TENANTS]

export default TENANTS

export interface PublicTenantConfig {
  cwr: {
    app_monitor_id: string
    guest_role_arn: string
    identity_pool_id: string
  }
  domain: string
  recaptcha: {
    recaptcha_key: string
  }
}
export type MapPublicTenantConfig = Record<TenantsType, PublicTenantConfig>
export type Auth = {
  secret: string
  base_url: string
  issuer_base_url: string
  client_id: string
  client_secret: string
  audience: string
} | null
export interface PrivateTenantConfig {
  auth0: Auth
  domain: string
  recaptcha: {
    sa_private_key: string
  }
}
export type MapPrivateTenantConfig = Record<TenantsType, PrivateTenantConfig>

export interface ConfigApp {
  description: string
  name: string
  region: RegionsType
  contact_url: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyles: any
  // firebase_tenant: string | null
  // gtm_id: string | null
}
export type MapConfigApp = Record<TenantsType, ConfigApp>
