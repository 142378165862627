import {
  CASANUESTRA_DEV,
  CASANUESTRA_STG,
  CASANUESTRA,
  CBANK,
  HSP_DEV,
  HSP_STG,
  HSP,
  LOCAL_CBANK,
  MAF_DEV,
  MAF_STG,
  MAF,
  SUPV_DEV,
  SUPV_STG,
  SUPV,
  TenantsType
} from 'consts/setupApp/tenants'

export const mappTenant: Record<string, TenantsType> = {
  [CASANUESTRA_DEV]: CASANUESTRA,
  [CASANUESTRA_STG]: CASANUESTRA,
  [HSP_DEV]: HSP,
  [HSP_STG]: HSP,
  [LOCAL_CBANK]: CBANK,
  [MAF_DEV]: MAF,
  [MAF_STG]: MAF,
  [SUPV_DEV]: SUPV,
  [SUPV_STG]: SUPV
}
